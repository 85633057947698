import { Button, Stack, Typography } from "@mui/material"

import { LoadingButton } from "@mui/lab"
import Section from "./Section"
import { useGlobalContext } from "@/context/global-context"

type Props = {
  message: string
  isLoading?: boolean
  onSubmit?: () => {}
  onCancel?: () => {}
  title?: string
}

export default function PromptBox({
  message,
  isLoading,
  onCancel,
  onSubmit,
  title = "",
}: Props) {
  const { closeModal } = useGlobalContext()
  const closeModalHandler = () => {
    if (onCancel) onCancel()
    closeModal()
  }

  return (
    <Section title={title}>
      <Stack spacing={2}>
        <Typography variant="bodyLight">{message}</Typography>
        <Stack direction="row" spacing={1}>
          <Button
            onClick={closeModalHandler}
            variant="outlined"
            color="warning"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={onSubmit}
            color="error"
          >
            Ok
          </LoadingButton>
        </Stack>
      </Stack>
    </Section>
  )
}
