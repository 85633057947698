import moment from "moment-jalaali"

const humanDate = (
  date: string | Date,
  format = "YYYY/MM/DD HH:mm:ss",
  locale = "en"
) => {
  return moment(date).locale(locale).format(format)
}

export default humanDate
