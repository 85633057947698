import { IconButton, Stack, SxProps, Theme, Typography } from "@mui/material"

import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined"
import { ReactNode } from "react"

type Props = {
  title: string
  icon?: ReactNode
  children: ReactNode
  sx?: SxProps<Theme>
  back?: () => void
  spacing?: number
}

export default function Section({
  title,
  children,
  icon,
  sx = {},
  back,
  spacing = 2,
}: Props) {
  return (
    <Stack spacing={spacing} sx={sx}>
      <Stack direction="row" alignItems="center" spacing={2}>
        {!!back && (
          <IconButton onClick={back}>
            <ArrowBackOutlinedIcon />
          </IconButton>
        )}
        <Typography
          component="strong"
          color={"text.primary"}
          variant="headingBold"
          sx={{ display: "flex", alignItems: "center", gap: 2 }}
        >
          {!!icon && icon}
          {title}
        </Typography>
      </Stack>
      <Stack>{children}</Stack>
    </Stack>
  )
}
