import { Button, Stack, TextField } from "@mui/material";
import { ChangeEvent, memo, useEffect, useState } from "react";

export type FaqItem = {
  answer: string;
  question: string;
};

type Props = {
  defaultItems?: FaqItem[];
  onChange?: (items: FaqItem[]) => void;
};

function FaqItems({ defaultItems = [], onChange }: Props) {
  const [items, setItems] = useState(defaultItems);
  useEffect(() => {
    if (defaultItems !== undefined) setItems(defaultItems);
  }, [defaultItems]);

  const handleAddFaqItem = () =>
    setItems((crt) => [...crt, { answer: "", question: "" }]);

  const handleChangeFaqItem =
    (e: ChangeEvent<HTMLInputElement>) => (index: number) => {
      const values = [...items];
      values[index][e.target.name] = e.target.value;
      setItems(values);
      if (onChange) onChange(values);
    };
  const handleDeleteFaqItem = (index: number) => {
    const values = items.filter((_, i) => index !== i);
    setItems(values);
    if (onChange) onChange(values);
  };

  return (
    <Stack spacing={4} width={"100%"}>
      {items.length > 0 && (
        <Stack spacing={4} width={"100%"}>
          {items.map((item, index) => (
            <Stack
              key={index}
              spacing={2}
              width={"100%"}
              alignItems={"flex-start"}
            >
              <TextField
                value={item.question}
                label="Question"
                name="question"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChangeFaqItem(e)(index)
                }
                fullWidth
              />
              <TextField
                value={item.answer}
                label="Answer"
                name="answer"
                multiline
                rows={6}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChangeFaqItem(e)(index)
                }
                fullWidth
              />
              <Button
                variant="outlined"
                color="error"
                onClick={() => handleDeleteFaqItem(index)}
              >
                Delete
              </Button>
            </Stack>
          ))}
        </Stack>
      )}
      <Stack direction={"row"}>
        <Button variant="contained" onClick={handleAddFaqItem}>
          Add Faq Item
        </Button>
      </Stack>
    </Stack>
  );
}

export default memo(FaqItems);
