//External import
import { PaletteOptions } from "@mui/material"
/**
 * Light palette
 */

export type ICustomizedPalette = PaletteOptions

const darkPalette = () => ({
  mode: "dark",
  primary: {
    main: "#e9965b",
  },
  label: {
    main: "#FFFFFF",
  },
  paper: {
    main: "#14192a",
    light: "#232b45",
  },
  background: {
    default: "#000000",
  },
})

export default darkPalette
