import { cssBaselineOverrides, variantsGenerator } from "."
import { darkPalette, lightPalette } from "./palettes"

import { AppConfig } from "@/config"
import { ThemeMode } from "@/store/settingsSlice"
import { createTheme } from "@mui/material/styles"
import { env } from "@/utils"

export const muiTheme = (themeMode: ThemeMode) => {
  const theme = createTheme({
    palette: (themeMode === "dark" ? darkPalette() : lightPalette()) as any,
    direction: env(AppConfig.Direction, "ltr"),
    components: {
      MuiCssBaseline: cssBaselineOverrides(),
      MuiTypography: {
        defaultProps: {
          color: "label.main",
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            borderRadius: 8,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: "none",
            textTransform: "capitalize",
            fontWeight: "bold",
            letterSpacing: "0px",
          },
        },
        variants: [
          {
            props: { variant: "contained" },
            style: {
              color: "#ffffff",
            },
          },
        ],
        defaultProps: {
          disableElevation: true,
        },
      },
    },
  })

  const media = theme.breakpoints.up("md")

  theme.components.MuiTextField = {
    styleOverrides: {
      root: {
        "& fieldset": {
          backgroundColor:
            themeMode === "dark"
              ? darkPalette().paper.light
              : lightPalette().paper.light,
          color: themeMode === "dark" ? "white" : "black",
        },
        "& input": {
          zIndex: 1,
        },
        "& .MuiInputBase-root .MuiSelect-outlined": {
          zIndex: 1,
        },
        "& .MuiInputBase-root .MuiInputAdornment-root": {
          zIndex: 1,
        },
        "& .MuiInputBase-root textarea": {
          zIndex: 1,
        },
      } as any,
    },
  }
  theme.typography.header = {
    fontSize: "1rem",
    lineHeight: "21px",
    [media]: {
      fontSize: "1.5rem",
      lineHeight: "37px",
    },
  }
  theme.typography.large = {
    fontSize: "0.875rem",
    lineHeight: "19px",
    [media]: {
      fontSize: "1.25rem",
      lineHeight: "28px",
    },
  }
  theme.typography.heading = {
    fontSize: "1rem",
    lineHeight: "25px",
    [media]: {
      fontSize: "1.125rem",
      lineHeight: "28px",
    },
  }
  theme.typography.body = {
    fontSize: "0.75rem",
    lineHeight: "15.5px",
    [media]: {
      fontSize: "1rem",
      lineHeight: "25px",
    },
  }
  theme.typography.subtitle = {
    fontSize: "0.75rem",
    lineHeight: "19px",
    [media]: {
      fontSize: "0.875rem",
      lineHeight: "22px",
    },
  }
  theme.typography.small = {
    fontSize: "0.5rem",
    lineHeight: "8px",
    [media]: {
      fontSize: "0.75rem",
      lineHeight: "12px",
    },
  }

  theme.typography = {
    ...theme.typography,
    ...variantsGenerator(theme.typography),
  }

  return theme
}
