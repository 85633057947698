import { useMemo } from "react"

const useMedia = (media: any[], cln: string, isArray: boolean = false) => {
  const targetMedia = useMemo(() => {
    if (!media?.length) return null

    return media.filter((item) => item.collection_name === cln)
  }, [cln, media])

  if (!media?.length) return null

  if (isArray) return targetMedia as any[]
  else return targetMedia[0] as any
}
export default useMedia
