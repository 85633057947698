//Built-in import

import { createContext, useContext, ReactNode, useState } from "react"

import { Drawer, SxProps } from "@mui/material"

//Internal import
import { Theme } from "@mui/material"
import { SharedModal } from "@/components/shared"

interface IGlobalContext {
  showModal: (element: ReactNode, sxProps?: SxProps<Theme>) => void
  closeModal: () => void
  showDrawer: (element: ReactNode, sxProps?: SxProps<Theme>) => void
  closeDrawer: () => void
}

const initialState: IGlobalContext = {
  showModal: (element: ReactNode, sxProps?: SxProps<Theme>) => {},
  closeModal: () => {},
  showDrawer: (element: ReactNode, sxProps?: SxProps<Theme>) => {},
  closeDrawer: () => {},
}

const GlobalContext = createContext(initialState)

interface IGlobalContextProviderProps {
  children?: React.ReactNode
}

interface IModalContext {
  id: number
  element: React.ReactNode
  isShowModal: boolean
  styles?: SxProps<Theme>
}

const GlobalContextProvider: React.FC<IGlobalContextProviderProps> = ({
  children,
}) => {
  const [modalContext, setModalContent] = useState<IModalContext[]>([])
  //function for opening modal
  const showModal = (element: React.ReactNode, sxProps?: SxProps<Theme>) => {
    setModalContent((crt) => {
      return [
        ...crt,
        {
          id: Math.floor(Math.random() * 10000),
          element,
          isShowModal: true,
          ...(sxProps && { styles: sxProps }),
        },
      ]
    })
  }
  //function for closing modal
  const closeModal = () => {
    setModalContent((crt) => [...crt].slice(0, -1))
  }
  //Variable for opening state of modal
  const [isShowDrawer, setShowDrawer] = useState(false)
  //Content of modal
  const [drawerContext, setDrawerContext] = useState<React.ReactNode>(null)
  //Variable for storing sx attrutes (For styling purposes)
  const [sxDrawer, setSxDrawer] = useState<SxProps<Theme>>({})

  const showDrawer = (element: ReactNode, sxProps?: SxProps<Theme>) => {
    //For setting sx modal attributes
    if (sxProps) setSxDrawer(sxProps)
    //Set drawer content
    setDrawerContext(element)
    setShowDrawer(true)
  }

  const closeDrawer = () => {
    // if (Boolean(Object.keys(sxDrawer).length)) setSxDrawer({});
    setShowDrawer(false)
    setTimeout(() => setDrawerContext(null), 300)
  }

  return (
    <GlobalContext.Provider
      value={{
        showModal,
        closeModal,
        showDrawer,
        closeDrawer,
      }}
    >
      {/* ModalComponent */}
      {modalContext.map((ctx, index) => {
        return (
          <SharedModal
            key={index}
            open={ctx?.isShowModal || false}
            handleClose={closeModal}
            sx={ctx?.styles}
          >
            {ctx.element}
          </SharedModal>
        )
      })}

      {/* ModalComponent */}
      {/* DrawerComponent */}
      <Drawer open={isShowDrawer} onClose={closeDrawer} sx={sxDrawer}>
        {drawerContext}
      </Drawer>
      {/* DrawerComponent */}
      {/* Children ReactNode */}
      {children}
      {/* Children ReactNode */}
    </GlobalContext.Provider>
  )
}

export const useGlobalContext = () => useContext(GlobalContext)
export default GlobalContextProvider
