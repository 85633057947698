import { CssBaseline, ThemeProvider } from "@mui/material"
import { useAppDispatch, useAppSelector } from "@/store/root"
import { useEffect, useState } from "react"

import { RouterProvider } from "react-router-dom"
import { api } from "@/services/http/api"
import { browserRouter } from "."
import { muiTheme } from "@/theme/mui"
import { setUser } from "@/store/authSlice"
import { useAuth } from "@/hooks"

export default function RouterConfig() {
  const dispatch = useAppDispatch()

  const { isLoggedIn } = useAuth()

  useEffect(() => {
    async function getProfile() {
      const res = await api.get("/profile")
      const userItem = res.data?.data?.item
      dispatch(setUser(userItem))
    }
    if (isLoggedIn) getProfile()
  }, [dispatch, isLoggedIn])

  const router = browserRouter()
  let themeMode = useAppSelector((store) => store.settings.mode)

  const [themeModeState, setThemeModeState] = useState(null)

  useEffect(() => {
    if (themeMode === "system") {
      const darkMatches = window.matchMedia("(prefers-color-scheme: dark)")

      setThemeModeState(darkMatches.matches ? "dark" : "light")
      darkMatches.addEventListener("change", (event) => {
        if (themeMode === "system")
          setThemeModeState(event.matches ? "dark" : "light")
      })
    } else {
      setThemeModeState(themeMode)
    }
  }, [themeMode])

  return (
    <ThemeProvider theme={muiTheme(themeModeState)}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  )
}
