import * as React from "react"
import { Stack, SxProps, Typography } from "@mui/material"
import { AllVariants } from "@/theme/declarations"
interface ITitleWithValueProps {
  title: string
  hasDoubleDot?: boolean
  value: string | React.ReactNode
  justifyContent?:
    | "flex-end"
    | "flex-start"
    | "center"
    | "space-between"
    | ("flex-end" | "flex-start" | "center" | "space-between")[]
  alignItems?:
    | "flex-end"
    | "flex-start"
    | "center"
    | "space-between"
    | ("flex-end" | "flex-start" | "center" | "space-between")[]
  direction?: "row" | "column" | "row-reverse" | "column-reverse" | any
  variantTitle?: AllVariants
  variantValue?: AllVariants
  spacing?: number | string
  colorValue?: string
  sxProps?: SxProps
}

const TitleWithValue: React.FunctionComponent<ITitleWithValueProps> = ({
  title,
  value,
  direction = "row",
  justifyContent = "flex-start",
  hasDoubleDot = true,
  alignItems = "center",
  variantTitle = "smallBold",
  variantValue = "bodyRegular",
  spacing = 1,
  colorValue = "text.secondary",
  sxProps,
}) => {
  return (
    <Stack
      direction={direction}
      alignItems={alignItems}
      justifyContent={justifyContent}
      width={"100%"}
      spacing={spacing}
      sx={sxProps}
      className="title-with-value"
    >
      <Typography variant={variantTitle} whiteSpace={"nowrap"}>
        {title} {hasDoubleDot ? ":" : ""}
      </Typography>
      {typeof value === ("string" || "number") ? (
        <Typography
          variant={variantValue}
          color={colorValue}
          whiteSpace={"nowrap"}
        >
          {value}
        </Typography>
      ) : (
        <>{value}</>
      )}
    </Stack>
  )
}

export default TitleWithValue
