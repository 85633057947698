/**
 * Since creating all font-faces takes lots of code lines, i placed them here for code organization.
 */

const cssBaselineOverrides = () => {
  return {
    styleOverrides: `
      .DatePicker input{
        border-radius:16px !important;
        border-color:rgba(0,0,0,0.23)
      }
    `,
  };
};

export default cssBaselineOverrides;
