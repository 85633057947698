//External import
import { PaletteOptions } from "@mui/material"
/**
 * Light palette
 */

export type ICustomizedPalette = PaletteOptions

const lightPalette = () => ({
  mode: "light",
  primary: {
    main: "#e9965b",
  },
  paper: {
    main: "#f7f7f7",
    light: "#fff",
  },
})

export default lightPalette
