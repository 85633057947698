import axios from "axios";
import store from "@/store/root";
import { signOut } from "@/store/authSlice";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
});

store.subscribe(() => {
  api.defaults.headers["Authorization"] =
    "Bearer " + store.getState().auth.token;
});

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 403) store.dispatch(signOut());
    return err;
  }
);

// store.subscribe(() => {
//   const token = store.getState().auth.token
//   if (!!token) store.dispatch(setToken(token))
// })
