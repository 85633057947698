import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined"
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined"

import ReactDOM from "react-dom"

type Errors = {
  errors: {
    [key: string]: string
  }
}

type Error = {
  error: string
}

type Message = {
  message: string
}

type ErrorData = Errors | Error | Message

type ToastType = "success" | "warning" | "error"

class ToastMessage {
  private static createToast(
    type: ToastType,
    message: string,
    timeDelay: number = 3000
  ) {
    const timeToDieTarget = timeDelay < 1000 ? 1000 : timeDelay

    let currentIcon: React.ReactNode

    switch (type) {
      case "success":
        currentIcon = <CheckCircleOutlineIcon sx={{ color: "black" }} />
        break
      case "warning":
        currentIcon = <WarningAmberOutlinedIcon sx={{ color: "black" }} />
        break
      case "error":
        currentIcon = <ErrorOutlineOutlinedIcon sx={{ color: "black" }} />
        break
    }

    const wrapper = document.querySelector("#root") as HTMLDivElement
    const el = document.createElement("div")
    const toast = document.createElement("div")
    const icon = document.createElement("div")
    icon.style.display = "flex"
    el.classList.add("toast-holder", `toast-${type}`)
    toast.classList.add("toast")
    toast.innerHTML = message

    ReactDOM.render(currentIcon, icon)
    el.appendChild(toast)
    el.appendChild(icon)

    wrapper.appendChild(el)

    setTimeout(() => {
      toast.classList.add("show")
      el.classList.add("show")
    }, 100)
    setTimeout(() => {
      el.classList.remove("show")
      setTimeout(() => {
        wrapper.removeChild(el)
      }, 300)
    }, timeToDieTarget)
  }

  public static success(message: string) {
    this.createToast("success", message)
  }
  public static warning(message: string) {
    this.createToast("warning", message)
  }
  public static error(data: ErrorData) {
    if ("errors" in data) {
      const errors = data?.errors
      for (let key in errors) {
        this.createToast("error", errors?.[key])
      }
    } else if ("message" in data) {
      this.createToast("error", data?.message)
    } else {
      this.createToast("error", data?.error)
    }
  }
}
export default ToastMessage
