import { Stack, TextField } from "@mui/material";
import { ChangeEvent, memo, useEffect, useState } from "react";

export type Related = {
  search: string;
};

type Props = {
  defaultItem?: Related;
  onChange?: (values: Related) => void;
};

function Related({ defaultItem, onChange }: Props) {
  const [item, setItem] = useState<Related>({ search: "" });
  useEffect(() => {
    if (defaultItem !== undefined) setItem(defaultItem);
  }, [defaultItem]);

  const handleChangeFaqItem = (e: ChangeEvent<HTMLInputElement>) => {
    setItem((crt) => ({ ...crt, [e.target.name]: e.target.value }));
  };

  const handleSave = () => {
    if (onChange) onChange(item);
  };

  return (
    <Stack spacing={2} width={"100%"} alignItems={"flex-start"}>
      <TextField
        value={item.search}
        label="Search"
        name="search"
        onChange={handleChangeFaqItem}
        onBlur={handleSave}
        fullWidth
      />
    </Stack>
  );
}

export default memo(Related);
