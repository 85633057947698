import { Navigate } from "react-router-dom"
import { ReactNode } from "react"
import { Routes } from "."
import { useAuth } from "@/hooks"

type Props = {
  element: ReactNode
}

export default function PrivateRoute({ element }: Props) {
  const { isLoggedIn } = useAuth()

  if (!isLoggedIn)
    return <Navigate to={`${Routes.auth.index}/${Routes.auth.login}`} />

  return <>{element}</>
}
