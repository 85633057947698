import { ReactNode, useMemo } from "react"
import { Stack, SxProps, Theme, Typography, alpha } from "@mui/material"
import { useLocation, useNavigate } from "react-router-dom"

type MenuItem = {
  title: string
  href: string
  icon?: ReactNode
}

type MenuItemProps = {
  menuItem: MenuItem
}
function SidebarMenuItem({ menuItem }: MenuItemProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const isActiveRoute = useMemo(() => {
    if (location.pathname.includes(menuItem.href)) return true
    return false
  }, [location.pathname, menuItem.href])

  function menuItemClickHandler() {
    if (!!!menuItem?.href) return null

    navigate(menuItem.href)
  }

  let menuStyle: SxProps<Theme> = {
    [`&:hover`]: {
      backgroundColor: (t) => t.palette.paper.main
    }
  }

  if (isActiveRoute)
    menuStyle = {
      backgroundColor: (t) => t.palette.paper.main,
      [`& .menu-icon-holder svg`]: {
        fill: (t) => t.palette.primary.main
      },
      [`& .menu-item-title`]: {
        color: (t) => t.palette.primary.main,
        fontWeight: "Bold"
      }
    }

  return (
    <Stack
      p={2}
      direction="row"
      spacing={2}
      sx={{
        borderRadius: (t) => t.shape.borderRadius,
        cursor: "pointer",
        ...menuStyle
      }}
      onClick={menuItemClickHandler}
    >
      {menuItem?.icon && (
        <Stack className="menu-icon-holder">{menuItem.icon}</Stack>
      )}
      <Typography className="menu-item-title">{menuItem.title}</Typography>
    </Stack>
  )
}

type Props = {
  menuItems: MenuItem[]
}

export default function SidebarMenu({ menuItems = [] }: Props) {
  if (menuItems.length === 0) return null

  return (
    <Stack
      className="menu-sidebar"
      component="nav"
      sx={{ py: 2, width: "calc(100% - 32px)" }}
      gap={1}
    >
      {menuItems.map((menuItem, key) => (
        <SidebarMenuItem key={key} menuItem={menuItem} />
      ))}
    </Stack>
  )
}
