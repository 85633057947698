import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export type ThemeMode = "dark" | "light" | "system"

type SettingsSlice = {
  language: string
  mode: ThemeMode
}

const initialState: SettingsSlice = {
  language: "en",
  mode: "light"
}

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLang(state, action: PayloadAction<string>) {
      state.language = action.payload
    },
    setMode(state, action: PayloadAction<ThemeMode>) {
      state.mode = action.payload
    }
  }
})

export const { setLang, setMode } = settingsSlice.actions

export default settingsSlice.reducer
