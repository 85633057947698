import { Stack } from "@mui/material"

export default function Logo() {
  return (
    <Stack
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        py: 1,
        width: 150,
        [`& img`]: {
          maxWidth: "100%"
        }
      }}
    >
      <img src={`/sarrafi-app.png`} alt="Logo" />
    </Stack>
  )
}
