import { ReactNode, Suspense } from "react"

type Props = {
  element: ReactNode
}

export default function LazyRoute({ element }: Props) {
  if (!element) return null

  return <Suspense fallback={<>Loading ...</>}>{element}</Suspense>
}
