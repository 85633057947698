import { SearchOutlined } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  SxProps,
  TextField,
  Theme,
  alpha,
} from "@mui/material";
import { FormEvent, useRef } from "react";

type Props = {
  placeholder?: string;
  onSubmit?: (val: string) => void;
  sx?: SxProps<Theme>;
};

export default function SearchBox({
  placeholder = "",
  onSubmit,
  sx = {},
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (onSubmit && inputRef.current) onSubmit(inputRef.current.value);
  };

  return (
    <Stack
      component={"form"}
      onSubmit={handleSubmit}
      sx={{
        position: "relative",
        ...sx,
      }}
    >
      <TextField placeholder={placeholder} inputProps={{ ref: inputRef }} />
      <IconButton
        sx={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
        }}
        type="submit"
      >
        <SearchOutlined />
      </IconButton>
    </Stack>
  );
}
