const querystring = require("querystring")

const getQueryParams = (obj: object) => querystring.stringify(obj)

export default function urlWithQueryParams(url: string, object: any) {
  if (typeof object !== "object") return ""

  if (!url) return ""

  const params = getQueryParams(object)

  return `${url}?${params}`
}
