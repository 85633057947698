import {
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
} from "@mui/material"
import React, { ChangeEvent, useState } from "react"

import { MyTableSkeleton } from "./skeleton"

import { SxProps, Theme } from "@mui/material"
import { ReactElement } from "react"

export interface ITableItems {
  title?: string
  render?: (item: any, index?: number) => ReactElement | string | number
}

export interface ITableProps {
  title?: string
  endButton?: React.ReactElement
  tableHeadItems?: ITableItems[]
  items: any
  selectable?: boolean
  onChangeSelectedIds?: (items: any) => void
  totalItems?: number
  loading?: boolean
  sxProps?: SxProps<Theme>
}

const MyTable: React.FC<ITableProps> = ({
  title = "",
  endButton,
  tableHeadItems = [],
  items,
  selectable = false,
  onChangeSelectedIds,
  totalItems,
  loading = false,
  sxProps = {},
}): React.ReactElement => {
  //Collect item id
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  //Fire onChangeSelected
  const updateSelectedEvent = (items: number[]) => {
    if (onChangeSelectedIds && typeof onChangeSelectedIds === "function")
      onChangeSelectedIds(items)
  }
  //Function to store item id
  const handleSelectId = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    if (selectedIds.indexOf(id) !== -1) {
      const newIds = selectedIds.filter((item) => item !== id)
      setSelectedIds(newIds)
      //Update event listener
      updateSelectedEvent(newIds)
    } else {
      const newIds = [...selectedIds, id]
      setSelectedIds(newIds)
      //Update event listener
      updateSelectedEvent(newIds)
    }
  }

  const MainTableContainer = () => (
    <TableContainer sx={{ mt: "0 !important" }}>
      <Table
        sx={{
          "& :last-child td, &:last-child th :not(thead)": {
            border: 0,
            // bgcolor: (t) => t.palette.common.white,
          },
          ...sxProps,
        }}
      >
        <TableHead
          sx={{
            "& tr th": {
              whiteSpace: "nowrap",
              textAlign: "center",
              borderBottom: 0,
              bgcolor: (t) => alpha(t.palette.common.black, 0.04),
              fontWeight: "bold",
              [`&:first-child`]: {
                borderTopLeftRadius: "8px !important",
                borderBottomLeftRadius: "8px !important",
              },
              [`&:last-child`]: {
                borderTopRightRadius: "8px !important",
                borderBottomRightRadius: "8px !important",
              },
            },
          }}
        >
          <TableRow>
            {/** Selectable th */}
            {selectable && <TableCell>#</TableCell>}
            {/** Selectable th */}
            {tableHeadItems.map((item, key) => (
              <TableCell key={key}>{item.title}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            "& td": {
              whiteSpace: "nowrap",
              textAlign: "center",
              p: 1.5,
            },
          }}
        >
          {loading ? (
            <MyTableSkeleton
              selectable={selectable}
              tableHeadItems={tableHeadItems}
            />
          ) : items.length === 0 ? (
            <TableRow>
              <TableCell colSpan={tableHeadItems.length + 5}>
                <Typography>Nothing was found</Typography>
              </TableCell>
            </TableRow>
          ) : (
            items.map((item, rowKey) => {
              //Selected value
              const isSelectedCheckbox = selectedIds.indexOf(item?.id) !== -1
              return (
                <TableRow key={rowKey}>
                  {/** Selectable checkbox */}
                  {selectable && (
                    <TableCell>
                      <Checkbox
                        checked={isSelectedCheckbox}
                        onChange={(e) => handleSelectId(e, item?.id)}
                      />
                    </TableCell>
                  )}
                  {/** Selectable checkbox */}
                  {tableHeadItems.map((tdItem, tdKey) => {
                    return (
                      <TableCell key={tdKey}>
                        {tdItem.render(item, rowKey)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <Stack spacing={2}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent="space-between"
        mb={1}
      >
        <Stack flexDirection={"row"} alignItems="baseline">
          {title && (
            <Typography
              sx={{ mr: 1 }}
              variant="subtitleBold"
              color="text.primary"
            >
              {title}
            </Typography>
          )}
          {totalItems && (
            <Typography variant="bodyRegular" color="label.main">
              rows quantity {totalItems}
            </Typography>
          )}
        </Stack>
        {endButton && endButton}
      </Stack>
      <MainTableContainer />
    </Stack>
  )
}

export default MyTable
