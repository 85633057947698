import { useAppDispatch, useAppSelector } from "@/store/root"
import { useCallback, useState } from "react"

import { api } from "@/services/http/api"
import { setToken } from "@/store/authSlice"

const useAuth = () => {
  const auth = useAppSelector((store) => store.auth)

  return {
    user: auth.user,
    isLoggedIn: !!auth?.token
  }
}
export default useAuth
