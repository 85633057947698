import { IMedia } from "@/types/media"
import { FunctionComponent, useState } from "react"
import UploaderWrapper from "../UploaderWrapper"
import {
  Box,
  alpha,
  Stack,
  Typography,
  IconButton,
  SxProps,
  Theme,
} from "@mui/material"
import AddOutlinedIcon from "@mui/icons-material/AddOutlined"
import PreviewHandler from "../PreviewHandler"
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined"
interface IUploaderProps {
  onUpload?: (m: IMedia | File | null) => void
  title?: string
  subTitle?: string
}

const Uploader: FunctionComponent<IUploaderProps> = ({
  onUpload,
  title = "Upload your file",
  subTitle,
}) => {
  const [media, setMedia] = useState(null)

  let parentStyle: SxProps<Theme> = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderRadius: 3,
    overflow: "hidden",
    height: 160,
    width: "100%",
    border: "1px dashed",
    borderColor: (t) => t.palette.primary.main,
    bgcolor: (t) => alpha(t.palette.paper.light, 0.5),
  }

  const coverViewHandler = (m: IMedia | File) => {
    setMedia(m)
    if (onUpload) onUpload(m)
  }

  const deleteCoverHandler = () => {
    setMedia(null)
    if (onUpload) onUpload(null)
  }

  let view = (
    <Stack justifyContent={"center"} alignItems={"center"} spacing={2}>
      <AddOutlinedIcon fontSize="large" />
      <Stack alignItems={"center"} justifyContent={"center"} spacing={2}>
        <Typography variant="bodyBold">{title}</Typography>
        {!!subTitle && <Typography variant="bodyLight">{subTitle}</Typography>}
      </Stack>
    </Stack>
  )

  if (!!media) {
    view = (
      <>
        <PreviewHandler media={media} />
        <IconButton
          onClick={deleteCoverHandler}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            bgcolor: (t) => t.palette.common.white,
            "&:hover": {
              bgcolor: (t) => t.palette.common.white,
            },
          }}
        >
          <DeleteOutlineOutlinedIcon color="error" />
        </IconButton>
      </>
    )
  }

  return (
    <UploaderWrapper onUpload={coverViewHandler} hasMedia={!!media}>
      {() => {
        return (
          <Box className={"uploader-container"} sx={parentStyle}>
            {view}
          </Box>
        )
      }}
    </UploaderWrapper>
  )
}

export default Uploader
