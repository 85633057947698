import { Chip, alpha } from "@mui/material"

import { FunctionComponent } from "react"

interface IStatusBoxProps {
  status: string
}
type colorType = string

const StatusBox: FunctionComponent<IStatusBoxProps> = ({ status }) => {
  let color: colorType = ""

  switch (status.toLowerCase()?.replaceAll(" ", "-")) {
    case "pending-user":
    case "pending":
      color = "#FFA000"
      break
    case "adminpending":
    case "pending-admin":
      color = "#607D8B"
      break
    case "done":
    case "accepted":
      color = "#388E3C"
      break

    case "rejected":
    case "closed":
      color = "#D32F2F"
      break

    default:
      color = "#1976D2"
      break
  }

  return (
    <Chip
      sx={{
        fontWeight: "bold",
        textTransform: "capitalize",
        bgcolor: alpha(color, 0.2),
        color
      }}
      label={status.replaceAll("-", " ")}
    />
  )
}

export default StatusBox
