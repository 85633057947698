import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type UserType = {
  banned_at: string | null;
  birthday: string | null;
  created_at: string | null;
  deleted_at: string | null;
  email: string | null;
  email_verified_at: string | null;
  first_name: string | null;
  full_name: string | null;
  id: number;
  gender?: string | null;
  last_name: string | null;
  mobile: string;
  mobile_verified_at: string | null;
  national_code: string | null;
  updated_at: string | null;
  uuid: string;
  media?: any;
  status: "AdminPending" | "Pending" | "Accepted" | "Rejected";
};

type AuthState = {
  user?: UserType | null;
  token?: string;
};

const initialState: AuthState = {
  user: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<any>) {
      state.user = action.payload;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    signOut(state) {
      state.user = null;
      state.token = null;
    },
  },
});

export const { setUser, signOut, setToken } = authSlice.actions;

export default authSlice.reducer;
