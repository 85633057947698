import { useState, useEffect } from "react"
import { FunctionComponent } from "react"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

type IMyDatePickerProps = {
  label: string
  onChange?: (date: Date | null) => void
  value?: string
}

const MyDatePicker: FunctionComponent<IMyDatePickerProps> = ({
  label,
  onChange,
  value,
}) => {
  const [currentValue, setCurrentValue] = useState(new Date())

  // handle change date state
  const handleDateChange = (date: Date | null) => {
    setCurrentValue(date)
    if (onChange) onChange(date)
  }

  useEffect(() => {
    if (value) setCurrentValue(new Date(value))
  }, [value])
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        value={currentValue}
        onChange={handleDateChange}
        format="yyyy/MM/dd"
        defaultValue={new Date()}
        sx={{
          width: "100%",
        }}
      />
    </LocalizationProvider>
  )
}

export default MyDatePicker
