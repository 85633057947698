import { Stack, TextField } from "@mui/material";
import { ChangeEvent, memo, useEffect, useState } from "react";

export type SEO = {
  heading: string;
  description: string;
};

type Props = {
  defaultItem?: SEO;
  onChange?: (values: SEO) => void;
};

function Seo({ defaultItem, onChange }: Props) {
  const [item, setItem] = useState<SEO>({ heading: "", description: "" });
  useEffect(() => {
    if (defaultItem !== undefined) setItem(defaultItem);
  }, [defaultItem]);

  const handleChangeFaqItem = (e: ChangeEvent<HTMLInputElement>) => {
    setItem((crt) => ({ ...crt, [e.target.name]: e.target.value }));
  };

  const handleSave = () => {
    if (onChange) onChange(item);
  };

  return (
    <Stack spacing={2} width={"100%"} alignItems={"flex-start"}>
      <TextField
        value={item.heading}
        label="Heading"
        name="heading"
        onChange={handleChangeFaqItem}
        onBlur={handleSave}
        fullWidth
      />
      <TextField
        value={item.description}
        label="Description"
        name="description"
        multiline
        rows={6}
        onChange={handleChangeFaqItem}
        onBlur={handleSave}
        fullWidth
      />
    </Stack>
  );
}

export default memo(Seo);
