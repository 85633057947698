import { FunctionComponent } from "react"
import { Stack, Typography } from "@mui/material"
import { IMedia } from "@/types/media"
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"
import { useImageUploaded } from "@/hooks"

interface IPreviewHandlerProps {
  media: File & IMedia
}

const PreviewHandler: FunctionComponent<IPreviewHandlerProps> = ({ media }) => {
  let preview = (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: (t) => t.palette.paper.light,
      }}
      alignItems="center"
      justifyContent={"center"}
      spacing={1}
    >
      <InsertDriveFileIcon sx={{ fontSize: 64 }} />
      {!!media?.name && (
        <Typography variant="extraSmallMedium">{media?.name}</Typography>
      )}
    </Stack>
  )

  const mediaSource = useImageUploaded(media)

  switch (media?.type || media?.mime_type) {
    case "image/jpeg":
    case "image/png":
    case "image/gif":
      preview = (
        <Stack sx={{ width: "100%", height: 200 }}>
          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={mediaSource}
            alt={media?.collection_name?.[0] ?? media?.name?.[0]}
          />
        </Stack>
      )
      break
  }

  return <>{preview}</>
}

export default PreviewHandler
