import "./global.css"
import "./toast.css"

import store, { persistor } from "@/store/root"

import { PersistGate } from "redux-persist/integration/react"
import { Provider } from "react-redux"
import { RouterConfig } from "./routes"

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <RouterConfig />
      </PersistGate>
    </Provider>
  )
}

export default App
