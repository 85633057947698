// eslint-disable-next-line no-extend-native
String.prototype.replaceAt = function (index, replacement) {
  return (
    this.substr(0, index) +
    replacement +
    this.substr(index + (replacement.length > 0 ? replacement.length : 1))
  )
}

// eslint-disable-next-line no-extend-native
String.prototype.withCommas = function () {
  const splitArray = this.split(".")
  if (splitArray.length === 1) return parseFloat(splitArray[0]).toLocaleString()
  return parseFloat(splitArray[0]).toLocaleString() + "." + splitArray[1]
}
// eslint-disable-next-line no-extend-native
Number.prototype.withCommas = function () {
  const splitArray = this.toString().split(".")
  if (splitArray.length === 1) return parseFloat(splitArray[0]).toLocaleString()
  return parseFloat(splitArray[0]).toLocaleString() + "." + splitArray[1]
}
// eslint-disable-next-line no-extend-native
String.prototype.withPriceUnit = function (price: string = "T") {
  return this + " " + price
}
// eslint-disable-next-line no-extend-native
Number.prototype.withPriceUnit = function (price: string = "T") {
  return this.toString() + " " + price
}

String.prototype.toEnglish = function () {
  return this.replace(/[\u0660-\u0669]/g, function (c) {
    return c.charCodeAt(0) - 0x0660
  }).replace(/[\u06f0-\u06f9]/g, function (c) {
    return c.charCodeAt(0) - 0x06f0
  })
}

export {}
