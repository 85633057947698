import { useState, FunctionComponent, ChangeEvent, useEffect } from "react"
import { TextFieldProps, TextField } from "@mui/material"

type Props = {
  label: string
  handleChange?: (value: string) => void
  defaultValue?: string | number
} & any

const AccountNumberField: FunctionComponent<Props & TextFieldProps> = ({
  label,
  handleChange,
  defaultValue,
  ...props
}) => {
  const [value, setValue] = useState("")

  const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    const formattedValue = formatAccountNumber(inputValue)

    setValue(formattedValue)

    if (handleChange) {
      handleChange(formattedValue)
    }
  }

  const formatAccountNumber = (value: string): string => {
    // Remove all non-numeric characters from the input value
    const numericValue = value.replace(/[^0-9]/g, "")
    // Add "IR" to the beginning of the value
    return numericValue
  }

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])
  return (
    <TextField
      label={label}
      inputProps={{
        inputMode: "numeric",
        maxLength: 26,
        ...props.inputProps,
      }}
      {...props}
      fullWidth
      onChange={handleValueChange}
      value={value}
    />
  )
}

export default AccountNumberField
