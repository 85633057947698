import { Box, SxProps, Theme } from "@mui/material"
import { ReactNode, useState } from "react"

type Props = {
  children: (upload?: any) => ReactNode
  hasMedia?: boolean
  onUpload?: (m: any) => void
}

export default function UploaderWrapper({
  children,
  onUpload,
  hasMedia = false,
}: Props) {
  const [isUploading, setIsUploading] = useState(false)

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) return
    setIsUploading(true)
    const media = e.target.files[0]
    setIsUploading(false)
    if (onUpload) onUpload(media)
  }

  let styles: SxProps<Theme> = {
    display: "flex",
    cursor: "pointer",
    width: "100%",
  }
  if (isUploading)
    styles = {
      opacity: 0.5,
      pointerEvents: "none",
    }

  return (
    <Box component={!!!hasMedia ? "label" : "div"} sx={styles}>
      <input type="file" hidden onChange={handleUpload} />
      {children()}
    </Box>
  )
}
